<template>
  <div>
    <Navbar page="Fases" link="/projetos" nameLink="Gestão de Projetos" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
       <div class="grid grid-cols-12">
        <div class="col-span-12">
          <button @click="openModal()" type="button" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }" class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full md:w-72 font-semibold text-center mb-2">
            <span class="inline-block mr-2">Nova Fase</span>
          </button>

        </div>
       </div>

       <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Nome
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id" class="hover:bg-gray-200 cursor-pointer">

                    <td class="px-2 py-4">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.nome ? item.nome : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-4 text-sm font-medium">
                      <button
                        @click="openModal(item._id)"
                        class="text-white text-sm hover:bg-blue-500 bg-blue-700 rounded py-1 mx-1 my-1 px-2">
                        Editar
                      </button>

                      <button
                        @click="remove(item._id)" 
                        class="text-white text-sm hover:bg-red-500 bg-red-700 rounded py-1 mx-1 my-1 px-2">
                        Excluir
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
      </div>
    </div>
    <modal name="faseModal" :adaptive="true" :height="'auto'">
      <div id="idmodal" class="px-3 py-5 overflow-auto h-full md:h-auto">
        <form action="#" method="POST">
          <div class="px-3 pb-4">
            <label for="nome" class="block text-sm font-medium">Nome Fase</label>
            <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            <div class="grid grid-cols-2">
              <div class="pt-3 text-left">
                  <button @click="$modal.hide('faseModal')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                      Voltar
                  </button>
              </div>
              <div class="pt-3 text-right">
                  <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-500">
                      Salvar
                  </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: 'tarefas/fases',
      form: {
        nome: "",
      },
      list: [],
      total: 0,
      page: 1,
      perPage: 10,
      query: { page: 1, limit: 10, skip: 0  },
    }
  },
  methods: {

    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, { skip: this.query.skip, limit: this.query.limit });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip };
      this.$router.push({ path: `/projetos/fases`, query: this.query });
    },

    async setPage(page) {
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/projetos/fases`, query: this.query });
    },

    async save() {
      const method = this.form._id ? 'put' : 'post';
      const req = await this.$http[method](`/v1/${this.route}/`, this.form);
      
      if (req.data.success) {
          this.$vToastify.success("Salvo com sucesso!");
          this.$modal.hide('faseModal');
          this.start();
      } else {
          this.$vToastify.error(req.data.err);
      }

    },
    
    async remove(id) {
      this.$confirm({
        title: 'Remover fase',
        message: `Deseja remover a fase selecionada ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.put(`/v1/${this.route}/`,{ _id: id, ativo: false });
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async openModal(id){
        if(id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }
        this.$modal.show('faseModal');
    },

  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>